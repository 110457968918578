<script>

export default {
    name: "CGUPage",
    components: {

    },
    props: ['templateMode'],
    created(){
         this.$emit('headerState', 1);
    },
    methods: {
        

    }
};
</script>

<style lang="scss">
    @import "cgu-page.scss";
</style>

<template src="./cgu-page.html"></template>